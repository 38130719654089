import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';
import { mediaQuery } from 'helpers/style-utils';

import { Container } from '../styles';

const FeatureContainer = styled.div`
  padding: 10px 0 75px;
  overflow: hidden;
`;

const Title = styled.h3`
  font-size: 5.5rem;
  line-height: 5.5rem;
  letter-spacing: -0.33rem;
  font-weight: 500;
  margin: 12px 0 14px 0;
`;

const Body = styled.div`
  margin-bottom: 41px;
  font-size: 2.4rem;
  line-height: 3.12rem;

  ${mediaQuery(900)`
    font-size: 2.4rem;
  `}
`;

const Note = styled.div`
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: ${p => p.theme.colors.gray};

  ${mediaQuery(800)`
    margin-bottom: 2.1rem;
  `}
`;

const Screenshot = styled.div`
  border-radius: 6px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};
  overflow: hidden;

  img {
    display: block;
  }
`;

const Wrapper = styled(Container)`
  display: flex;

  ${mediaQuery(800)`
    display: block;
  `}
`;

const Left = styled.div`
  width: 347px;
  flex-shrink: 0;

  ${mediaQuery(900)`
    width: 340px;
  `}

  ${mediaQuery(800)`
    width: 100%;
  `}
`;

const Right = styled.div``;

type Props = {
  title: string;
  text: string;
  note?: string;
  screenshot: React.ReactNode;
};

const Feature = ({ title, text, note, screenshot }: Props) => {
  const { t } = useTranslation('about');

  return (
    <FeatureContainer>
      <Wrapper>
        <Left>
          <Title>
            <Trans i18nKey={title} t={t} />
          </Title>
          <Body>
            <Trans i18nKey={text} t={t} />
          </Body>
          {note && (
            <Note>
              <Trans i18nKey={note} t={t} />
            </Note>
          )}
        </Left>
        <Right>
          <Screenshot>{screenshot}</Screenshot>
        </Right>
      </Wrapper>
    </FeatureContainer>
  );
};

export default Feature;
