import React, { useEffect } from 'react';
import { fullstory } from 'lib/track';
import AboutPage from 'components/about/AboutPage';

const About = () => {
  useEffect(() => fullstory('shutdown'), []);

  return <AboutPage />;
};

About.getInitialProps = async () => ({
  namespacesRequired: ['about', 'registration'],
});

export default About;
