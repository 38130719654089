import { mediaSelector } from 'client/helpers/style-utils';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1115px;
  margin: 0 auto;
  padding: 0px 30px;
  width: 100%;
`;

export const Description = styled.div`
  font-size: 1.8rem;
  letter-spacing: -0.091rem;
  color: ${p => p.theme.currentTheme.notes};
`;

export const TitleText = styled.div`
  font-size: 5.5rem;
  line-height: 5.5rem;
  letter-spacing: -0.332rem;

  > strong {
    font-weight: 500;
  }

  ${mediaSelector.mobile} {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
`;

export const Text = styled.div`
  font-size: 2.4rem;
  line-height: 3.12rem;
`;

export const ScreenShot = styled.div``;
