import React from 'react';

import Header from './header/Header';
import HeadingBlock from './heading-block/HeadingBlock';
import QuotesBlock from './quotes-block/QuotesBlock';
import FeaturesBlock from './features-block/FeaturesBlock';

export default function AboutPage() {
  return (
    <>
      <Header />
      <HeadingBlock />
      <QuotesBlock />
      <FeaturesBlock />
    </>
  );
}
