import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'lib/i18n';

import { staticUrl, contextUrl } from '../lib/utils';

import { TitleText, Container } from '../styles';
import Feature from './Feature';

const FeaturesContainer = styled.div`
  margin-top: 48px;
`;

const StyledTitleText = styled(TitleText)`
  margin-bottom: 55px;

  em {
    font-style: normal;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 5px;
      right: -5px;
      left: 0px;
      height: 5px;
      z-index: -1;
      border-radius: 5px;
      background: ${p => p.theme.colors.dodgerBlue};
    }
  }
`;

const FeaturesWrapper = styled.div`
  padding-top: 128px;
`;

const FEATURES_LOCALE_KEYS = ['movies', 'roadmap', 'recipes', 'teamwork', 'diet'];

export default function FeaturesBlock() {
  const { t } = useTranslation('about');

  return (
    <FeaturesContainer>
      <Container>
        <StyledTitleText>
          <Trans i18nKey="featuresBlock.title" t={t} />
        </StyledTitleText>
        <TitleText>
          <Trans i18nKey="featuresBlock.subTitle" t={t} />
        </TitleText>
      </Container>
      <FeaturesWrapper>
        {FEATURES_LOCALE_KEYS.map(key => (
          <Feature
            key={key}
            title={`features.${key}.title`}
            text={`features.${key}.text`}
            screenshot={
              <img
                src={staticUrl('features-chat.png', contextUrl.ABOUT)}
                width="960"
                height="504"
                alt=""
              />
            }
          />
        ))}
      </FeaturesWrapper>
    </FeaturesContainer>
  );
}
